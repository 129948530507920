import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Landing from "./pages/Landing/Landing";
import White from "./pages/White Landing/White Landing";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<White />} />
        <Route path="/white" element={<White />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
