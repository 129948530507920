import React, { useEffect, useRef, useState } from "react";

const videoSources = [
  { quality: "240p", src: "/videos/background-240p.mp4" },
  { quality: "360p", src: "/videos/background-360p.mp4" },
  { quality: "480p", src: "/videos/background-480p.mp4" },
  { quality: "720p", src: "/videos/background-720p.mp4" },
  { quality: "1080p", src: "/videos/background.mp4" },
];

const VideoBackground = () => {
  const [currentSourceIndex, setCurrentSourceIndex] = useState(0);
  const [nextSourceIndex, setNextSourceIndex] = useState(1);
  const [currentSrc, setCurrentSrc] = useState(videoSources[0].src);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleCanPlayThrough = () => {
      if (nextSourceIndex < videoSources.length) {
        // wait for 1 second before switching to the next video

        setCurrentSourceIndex(nextSourceIndex);
        setNextSourceIndex(nextSourceIndex + 1);
        setCurrentSrc(videoSources[nextSourceIndex].src);
      }
    };

    video.addEventListener("canplaythrough", handleCanPlayThrough);

    return () => {
      video.removeEventListener("canplaythrough", handleCanPlayThrough);
    };
  }, [nextSourceIndex]);

  useEffect(() => {
    const video = videoRef.current;
    const currentSrc = videoSources[currentSourceIndex].src;
    const wasPlaying = !video.paused;
    const currentTime = video.currentTime;

    video.src = currentSrc;
    video.currentTime = currentTime;
    if (wasPlaying) {
      video.play();
    }
  }, [currentSourceIndex]);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        playsinline
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "100%",
          minHeight: "100%",
          width: "100vw",
          height: "100vh",
          zIndex: "-1000",
          transform: "translate(-50%, -50%)",
          objectFit: "cover",
        }}
      >
        <source src={currentSrc} type="video/mp4" />
      </video>
      <img
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "100%",
          minHeight: "100%",
          width: "100vw",
          height: "100vh",
          zIndex: "-2000",
          transform: "translate(-50%, -50%)",
          objectFit: "cover",
        }}
        src="/images/background.gif"
        alt="hero"
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: "-999",
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 65.5%, rgba(0, 0, 0, 0.59) 92.5%, rgba(0, 0, 0, 0.71) 100%)",
        }}
      ></div>
    </div>
  );
};

export default VideoBackground;
