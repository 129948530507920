import "./Benefits.css";

import data from "../../data/benefits.json";
const benefits = data.benefits;

export default function Benefits() {
  return (
    <div className="benefits-page flex-container-column">
      <div className="section-header-container benefits-header-container flex-container-row flex-item">
        <h3 className="section-header">Why choose us?</h3>
        <img
          src="/images/highlight.png"
          alt="star"
          className="section-header-image"
        />
      </div>
      <div className="benefits-container flex-container-row flex-item">
        {benefits.map((benefit, index) => (
          <div className="benefit flex-container-row flex-item">
            <img src={benefit.image} alt="benefit" className="benefit-icon" />
            <div className="benefit-text-container flex-container-column">
              <h3 className="benefit-header flex-item">{benefit.title}</h3>
              <p className="benefit-text flex-item">{benefit.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
