import "./Navbar.css";

export default function Navbar() {
  const scrollToSection = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="navbar flex-container-row">
      <div className="navbar-logo-container flex-container-row flex-item">
        <img
          src="/images/logo.png"
          alt="logo"
          className="navbar-logo flex-item"
        />
        <h1 className="navbar-title flex-item">Vibhuti Fashions</h1>
      </div>
      <div className="navbar-links-container flex-container-row flex-item">
        <section
          onClick={() => scrollToSection("home")}
          className="navbar-link flex-item"
        >
          Home
        </section>
        <section
          onClick={() => scrollToSection("about-us")}
          className="navbar-link flex-item"
        >
          About Us
        </section>
        <section
          onClick={() => scrollToSection("products")}
          className="navbar-link flex-item"
        >
          Products
        </section>
        <a
          href="mailto:primeinternational2@yahoo.com"
          className="navbar-link contact-us flex-item"
        >
          Contact Us
        </a>
      </div>
    </nav>
  );
}
