import instance from "./init";

export const submitForm = async (name, email, mobileno, message) => {
  return await instance
    .post("/clsyc5o080ze1rolcn6jpap6r/responses", {
      userId: "1",
      surveyId: "clv0m506y02gjw30dmvb983pi",
      finished: true,
      data: {
        mrscnwn78qknpsqmtpocr99n: name,
        z51ayydj5ig8emjtlbf3dvqy: email,
        oln7arnes98oo0g8pm66qaob: mobileno,
        ff67wqn3h3a0nh9aaa44uamr: message,
      },
    })
    .then((response) => {
      console.log(response.data);
      return response;
    })
    .catch((error) => {
      return { error: error.message };
    });
};
