import "./About.css";
import data from "../../data/features.json";
const features = data.features;

export default function About() {
  return (
    <div id="about-us" className="about-page flex-container-column">
      <div className="section-header-container flex-container-row flex-item">
        <h3 className="section-header about-us-header-container">About Us</h3>
        <img
          src="/images/highlight.png"
          alt="star"
          className="section-header-image"
        />
      </div>
      <div className="about-us-body-container flex-container-row flex-item">
        <img
          src="/images/about-us-image.jpg"
          alt="hero"
          className="about-us-image flex-item"
        />
        <div className="about-us-description-container flex-container-column">
          <h3 className="about-us-description-header flex-item">
            Vibhuti Fashions
          </h3>
          <p className="about-us-description flex-item">
            We at vibhuti fashions produce high class quality products
            comprising of Raschel, jacquardtronic ,warp knitted fabrics and
            trimmed laces. Since its inception in the year 2015 at
            Amritsar,punjab we have carved a niche as a leading manufacturer of
            fabrics. We have been dealing in polyster , nylon,cotton and spandex
            fabrics. We have a set up of modern state of art machinery along with
            inhouse designing which helps in customising various designs as per
            the customers requirement.
          </p>
{/*           <div className="about-us-button-container flex-container-row">
            <section className="about-us-button-text flex-item">
              Check out our services
            </section>
            <img
              src="/images/arrow-right.png"
              alt="arrow"
              className="about-us-button-arrow flex-item"
            />
          </div> */}
        </div>
      </div>
      <div className="about-us-feature-container flex-container-row flex-item">
        {features.map((feature, index) => (
          <div className="about-us-feature flex-container-column">
            <img
              src={feature.image}
              alt="growth"
              className="about-us-feature-image flex-item"
            />
            <h3 className="about-us-feature-header flex-item">
              {feature.title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}
