// import { Alert } from "@mui/material";
import "./Contact.css";
// import { useEffect, useState } from "react";
const formAPI = require("../../api/form");

export default function Contact() {
  function formSubmit(event) {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const message = event.target.message.value;
    const mobileno = event.target.mobileno.value;
    formAPI.submitForm(name, email, mobileno, message).then((response) => {
      if (response.error) {
        alert("Error: " + response.error);
      } else {
        alert("Form submitted successfully");
      }
    });
  }

  function resizeMessage() {
    const element = document.getElementById("message");
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
  }

  return (
    <div id="contact-us" className="contact-page flex-container-column">
      <div className="contact-us-container flex-container-row">
        <div className="contact-us-left flex-container-column">
          <h3 className="contact-us-left-header flex-item">Have an inquiry?</h3>
          <h3 className="contact-us-left-header flex-item">Contact Us!</h3>
          <p className="contact-us-left-text flex-item">
            Have an inquiry? We'll be happy to assist you
          </p>
          <div className="contact-us-info-container flex-container-column">
            <div className="contact-us-info flex-container-row flex-item">
              <img
                className="contact-us-info-icon"
                src="images/Phone.png"
                alt="phone"
              />
              <p className="contact-us-info-text">
                +91 9356004355, +91 9814059380
              </p>
            </div>
            <div className="contact-us-info flex-container-row flex-item">
              <img
                className="contact-us-info-icon"
                src="images/Email.png"
                alt="email"
              />
              <p className="contact-us-info-text">
                primeinternational2@yahoo.com
              </p>
            </div>
            <div className="contact-us-info flex-container-row flex-item">
              <img
                className="contact-us-info-icon"
                src="images/Map Pin.png"
                alt="location"
              />
              <p className="contact-us-info-text">
                Vibhuti Fashions, Pradhan Mantri Gramin Vikas Yojna Sadak Bal
                Kalan, Majitha Road, Amritstar
              </p>
            </div>
          </div>
        </div>
        <div className="contact-us-right flex-container-column">
          <form
            onSubmit={formSubmit}
            className="contact-us-form flex-container-column"
          >
            <h3 className="contact-us-form-header flex-item">
              Fill in your details
            </h3>
            <div className="contact-us-form-input-container flex-container-column">
              <label className="contact-us-form-label flex-item" htmlFor="name">
                Name
              </label>
              <input
                className="contact-us-form-input flex-item"
                type="text"
                id="name"
                name="name"
                required
              />
            </div>
            <div className="contact-us-form-input-container flex-container-column">
              <label
                className="contact-us-form-label flex-item"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="contact-us-form-input flex-item"
                type="email"
                id="email"
                name="email"
                required
              />
            </div>
            <div className="contact-us-form-input-container flex-container-column">
              <label
                className="contact-us-form-label flex-item"
                htmlFor="mobileno"
              >
                Mobile
              </label>
              <input
                className="contact-us-form-input flex-item"
                type="tel"
                id="mobileno"
                name="mobileno"
                required
              />
            </div>
            <div className="contact-us-form-input-container flex-container-column">
              <label
                className="contact-us-form-label flex-item"
                htmlFor="message"
              >
                Enter your query
              </label>
              <textarea
                className="contact-us-form-input flex-item"
                id="message"
                onChange={resizeMessage}
                name="message"
                required
              ></textarea>
            </div>
            <button className="contact-us-form-submit flex-item">Submit</button>
          </form>
          {/* {showAlert ? (
            <Alert severity="success">
              This success Alert has a custom icon.
            </Alert>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}
