import "./Products.css";

import data from "../../data/products.json";
const products = data.products;

export default function Products() {
  return (
    <div id="products" className="products-page flex-container-column">
      <div className="section-header-container products-container-header flex-container-row flex-item">
        <h3 className="section-header">Our Products</h3>
        <img
          src="/images/highlight.png"
          alt="star"
          className="section-header-image"
        />
      </div>
      <div className="products-container flex-container-row flex-item">
        {products.map((product, index) => (
          <div className="product flex-container-column">
            <div className="image-container">
              <div className="image-wrapper">
                <img
                  src={product.image}
                  alt="product"
                  className="product-image flex-item"
                />
              </div>
            </div>
            <h3 className="product-header flex-item">{product.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}
