import "./Hero.css";
import Navbar from "../Navbar/Navbar";
import VideoBackground from "../Video Background/Video";

export default function Hero() {
  const sendMessageToWhatsApp = (message) => {
    if (message) {
      window.open(`https://wa.me/919356004355?text=${message}`, "_blank");
    } else {
      window.open(`https://wa.me/919356004355`, "_blank");
    }
  };

  const scrollToSection = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="home" className="hero-container flex-container-column">
      <VideoBackground />
      <Navbar />
      <div className="hero-content-container flex-container-row">
        <div className="hero-header-container flex-container-column">
          <h2 className="hero-header flex-item">Best Quality Fabrics for</h2>
          <h2 className="hero-header flex-item">your Garments</h2>
          <div
            onClick={() => scrollToSection("contact-us")}
            className="hero-button-container flex-container-row"
          >
            <section className="hero-button-text flex-item">
              Send an enquiry
            </section>
            <img
              src="/images/arrow-right.png"
              alt="arrow"
              className="hero-button-arrow flex-item"
            />
          </div>
        </div>
        <div className="hero-description-container flex-container-column">
          <p className="hero-description flex-item">
            Vibhuti Fashions, at Amritsar, Punjab, India has carved a niche as
            leading manufacturer, exporter, trader and supplier in the apparel
            business.
          </p>
          <img
            src="/images/whatsapp-icon.png"
            alt="hero"
            onClick={() => sendMessageToWhatsApp()}
            className="hero-image flex-item"
          />
        </div>
      </div>
    </div>
  );
}
