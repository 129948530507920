import "./Navigation.css";

export default function Navigation() {
  const scrollToSection = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="fixed-navigation-container flex-container-row">
      <section
        onClick={() => scrollToSection("home")}
        className="fixed-navigation-link navbar-link flex-item"
      >
        Home
      </section>
      <section
        onClick={() => scrollToSection("about-us")}
        className="fixed-navigation-link navbar-link flex-item"
      >
        About Us
      </section>
      <section
        onClick={() => scrollToSection("products")}
        className="fixed-navigation-link navbar-link flex-item"
      >
        Products
      </section>
      <a
        href="mailto:primeinternational2@yahoo.com"
        className="fixed-navigation-link navbar-link contact-us flex-item"
      >
        Contact Us
      </a>
    </div>
  );
}
