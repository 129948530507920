import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-page flex-container-column">
      <div className="footer-container flex-container-row flex-item">
        <div className="footer-description-container flex-container-column flex-item">
          <h3 className="footer-description-header">Vibhuti Fashions</h3>
          <p className="footer-description">
            Vibhuti Fashions, at Amritsar, Punjab, India has carved a niche as
            leading manufacturer, for over 9 years now.
          </p>
          <div className="footer-socials-container flex-container-column">
            <div className="footer-social-links flex-container-row flex-item">
              <img
                className="footer-social-link"
                src="/images/Phone.png"
                alt="Phone"
              />
              <section className="footer-social-text">
                +91 9356004355, +91 9814059380
              </section>
            </div>
            <div className="footer-social-links flex-container-row flex-item">
              <img
                className="footer-social-link"
                src="/images/Email.png"
                alt="Email"
              />
              <section className="footer-social-text">
                primeinternational2@yahoo.com
              </section>
            </div>
          </div>
        </div>
        <div className="footer-services-container flex-container-column flex-item">
          <h3 className="footer-services-header footer-section-header">
            Products
          </h3>
          <div className="footer-services flex-container-column flex-item">
            <h4 className="footer-service">Warp Knitted Fabrics</h4>
            <h4 className="footer-service">Trimmed Laces</h4>
            <h4 className="footer-service">Raschel Fabrics</h4>
            <h4 className="footer-service">Tricot Fabrics</h4>
            <h4 className="footer-service">Jacquardtronic Fabrics</h4>
            <h4 className="footer-service">Curtains</h4>
          </div>
        </div>
        <div className="footer-location-container flex-container-column flex-item">
          <h3 className="footer-location-header footer-section-header">
            Location
          </h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3394.275925171046!2d74.92090227561913!3d31.708356874128395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDQyJzMwLjEiTiA3NMKwNTUnMjQuNSJF!5e0!3m2!1sen!2sin!4v1708625855262!5m2!1sen!2sin"
            width="200"
            height="300"
            className="footer-location-map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
