import Hero from "../../components/Hero Section/Hero";
import About from "../../components/About Us/About";
import Products from "../../components/Products/Products";
import Contact from "../../components/Contact Us/Contact";
import Footer from "../../components/Footer/Footer";
import Benefits from "../../components/Benefits/Benefits";
import Navigation from "../../components/Fixed Navigation/Navigation";
import { useEffect } from "react";

export default function White() {
  useEffect(() => {
    const loadCSS = async () => {
      try {
        const { default: CSS } = await import("./White Landing.css");
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = CSS;
        document.head.appendChild(link);
      } catch (error) {
        console.error("Failed to load CSS:", error);
      }
    };
    loadCSS();
    return () => {
      const link = document.querySelector('link[href*="White Landing.css"]');
      if (link) {
        link.parentNode.removeChild(link);
      }
    };
  }, []);
  return (
    <div className="landing-page flex-container-column">
      <Hero />
      <About />
      <Products />
      <Benefits />
      <Contact />
      <Footer />
      <Navigation />
    </div>
  );
}
